











































































import Vue from "vue";

export default Vue.extend({
  name: "OnboardingDecoration",
  data() {
    return {
      is_faq: true
    };
  },
  methods: {
    toggle_tab() {
      this.is_faq = !this.is_faq;
    }
  }
});
